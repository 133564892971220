import {
  PublicEvent,
  PublicEventResponse,
  TokenEvent,
  Whitelist,
} from './interfaces/event.interfaces';

/**
 * Class used by external third parties to receive events from KBC
 */
export class PostmessageExternalReceiver {
  private publicEventCallback: (event) => void;
  public publicEventRemoveListener: () => void;
  private publicEventResponseCallback: (event) => void;
  public publicEventResponseRemoveListener: () => void;
  private tokenEventCallback: (event) => void;
  public tokenEventRemoveListener: () => void;

  public constructor(
      private readonly origins: (Whitelist|string)[] = [],
      private readonly windowInstance: Window = window) {
    origins.push(Whitelist.MOBILE_FET, Whitelist.MOBILE_ACC, Whitelist.MOBILE_PRO);
  }

  private parsePublicPayload(event: MessageEvent): PublicEvent {
    try {
      const parsedEvent = JSON.parse(event.data);
      if ('api' in parsedEvent && !('token' in parsedEvent) && !('response' in parsedEvent)) {
        return parsedEvent;
      }
      throw new Error(`Unknown event type`);
    } catch (e) {
      throw new Error(`Unable to parse event ${event.data}`);
    }
  }

  private parsePublicPayloadResponse(event: MessageEvent): PublicEventResponse {
    try {
      const parsedEvent = JSON.parse(event.data);
      if ('api' in parsedEvent && !('token' in parsedEvent) && 'response' in parsedEvent &&
          (parsedEvent.api !== 'init')) {
        return parsedEvent;
      }
      throw new Error(`Unknown event type`);
    } catch (e) {
      throw new Error(`Unable to parse event ${event.data}`);
    }
  }

  private allowOrigin(event: MessageEvent): boolean {
    return this.origins.includes(event.origin);
  }

  private parseTokenPayload(event: MessageEvent): TokenEvent {
    try {
      const parsedEvent = JSON.parse(event.data);
      if ('api' in parsedEvent && 'token' in parsedEvent) {
        return parsedEvent;
      }
      // custom parser for init call
      if (parsedEvent.api === 'init') {
        parsedEvent.token = parsedEvent.response.authorizationCode;
        delete parsedEvent.response;
        return parsedEvent;
      }
      throw new Error(`Unknown event type`);
    } catch (e) {
      throw new Error(`Unable to parse event ${event.data}`);
    }
  }

  public addPublicEventListener(
      callback: (api: string, data: object, version: string, hash?: string) => void): () => void {
    this.publicEventCallback = (event) => {
      if (this.allowOrigin(event as MessageEvent)) {
        try {
          const {api, data, version, hash} = this.parsePublicPayload(event);
          callback(api, data, version, hash);
        } catch (e) {
          // not a public event
        }
      }
    };
    this.windowInstance.addEventListener('message', this.publicEventCallback);
    this.publicEventRemoveListener = () => {
      this.windowInstance.removeEventListener('message', this.publicEventCallback);
    };
    return this.publicEventRemoveListener;
  }

  public addPublicEventResponseListener(
      callback: (api: string, response: any, version: string, hash?: string) => void): () => void {
    this.publicEventResponseCallback = (event) => {
      if (this.allowOrigin(event as MessageEvent)) {
        try {
          const {api, response, version} = this.parsePublicPayloadResponse(event);
          callback(api, response, version);
        } catch (e) {
          // not a public event
        }
      }
    };
    this.windowInstance.addEventListener('message', this.publicEventResponseCallback);
    this.publicEventResponseRemoveListener = () => {
      this.windowInstance.removeEventListener('message', this.publicEventResponseCallback);
    };
    return this.publicEventResponseRemoveListener;
  }

  public addTokenEventListener(callback: (api: string, token: Object, version: string) => void):
      () => void {
    this.tokenEventCallback = (event) => {
      if (this.allowOrigin(event as MessageEvent)) {
        try {
          const {api, token, version} = this.parseTokenPayload(event);
          callback(api, token, version);
        } catch (e) {
          // not a token event
        }
      }
    };
    this.windowInstance.addEventListener('message', this.tokenEventCallback);
    this.tokenEventRemoveListener = () => {
      this.windowInstance.removeEventListener('message', this.tokenEventCallback);
    };
    return this.tokenEventRemoveListener;
  }
}
